// assets
import CardTimeline from "./assets/img/system-uicons_card-timeline.png";

import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Img,
  Link,
  Progress,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
// import { columnsDataComplex } from "../dataTables/variables/columnsData";
import ComplexTable from "./components/ComplexTable";
import tableDataComplex from "../../admin/default/variables/tableDataComplex.json";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Cards } from "./data/ProjectData";
import CustomCard from "./components/CustomCard";
import { HSeparator } from "components/separator/Separator";
import AvatarImg from "views/admin/project/assets/img/avatar1.png";
import ProjectCard from "./components/ProjectCard";
import { OngoingProjects } from "./data/ProjectData";
import {
  ColumnData,
  RowData,
  EnquiryColumn,
  EnquiryRow,
  PaymentHistoryRow,
  PaymentHistoryColumn
} from "./data/ProjectData";
import EnquiryTable from "./components/EnquiryTable";
import PaymentHistoryTable from "./components/OrderHistoryTable";

const Project = () => {
  const [cards, setCards] = useState([]);
  const [ongoingProjects, setOngoingProjects] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [enquiryColumnData, setEnquiryColumnData] = useState([]);
  const [enquiryRowData, setEnquiryRowData] = useState([]);
  const [paymentHistoryColumnData, setPaymentHistoryColumnData] = useState([]);
  const [paymentHistoryRowData, setPaymentHistoryRowData] = useState([]);
  // const itemsPerPage=6;
  const [currentPage,setCurrentPage] = useState(1);

  useEffect(() => {
    setCards(Cards);
    setOngoingProjects(OngoingProjects);
    setColumnData(ColumnData);
    setRowData(RowData);
    setEnquiryColumnData(EnquiryColumn);
    setEnquiryRowData(EnquiryRow);
    setPaymentHistoryColumnData(PaymentHistoryColumn);
    setPaymentHistoryRowData(PaymentHistoryRow);
  });
  
  const handleNext =()=>{
    setCurrentPage((prevPage)=>Math.min(prevPage+1,totalPages))
  }
  const handlePrev =()=>{
    setCurrentPage((prevPage)=>Math.max(prevPage-1,1))
  }
  const isMobile = useBreakpointValue({ base: true, md: false });
  const itemsPerPage = isMobile ? 3 : 6; 
 const totalPages = Math.ceil(ongoingProjects.length/itemsPerPage);
 const startIndex = (currentPage-1)*itemsPerPage;
 const endIndex = startIndex+itemsPerPage;
 const displayedProjects  = ongoingProjects.slice(startIndex,endIndex)

 
  return (
    <>
      <Flex maxW="100%" justifyContent="space-between">
        <Text fontWeight="bold" fontSize="25px">
          Overview 
        </Text>
      </Flex>
      
      <Flex mt="2rem" flexWrap="wrap" justifyContent="space-between">
        {cards.map((item, index) => (
          <CustomCard key={index} card={item} />
        ))}
      </Flex>

      <Flex position="relative" direction="column">
        <Tabs>
          <TabList justifyContent="space-between">
            <Tab>Ongoing Projects</Tab>
            <Tab>Completed Projects</Tab>
            
              <Flex alignItems="center">
                <Img src={CardTimeline} alt="CardTimeline" boxSize="20px" mr="2" />
             
                {/* <Text mr="2">Past 15 days</Text> */}
                {/* <Text mr="2">days</Text> */}
                {/* <ChevronDownIcon/>
                <Text fontSize="12px" mr="2">
                  17 Jul 2024
                </Text>
                <Text fontSize="12px" mr={2}> to</Text>
                <Text fontSize="12px"> 08 Aug 2024</Text> */}
              </Flex>
        
          </TabList>
          <TabPanels>
            <TabPanel>
              <Flex
                mt="1rem"
                flexWrap="wrap"
                // flexDirection="row"
                justifyContent="space-between"
                flexDirection={{ base: "column", md: "row" }}
                gap="4"
              >
                {displayedProjects.map((item, index) => (
                  <Box key={index} w={{ base: "100%", md: "30%" }} mb={{ base: "1rem", md: "0" }}>
                    <ProjectCard props={item} />
                  </Box>
                ))}
              </Flex>
                {/* Pagination Controls */}
            <Flex justifyContent="center" mt="2rem" alignItems="center">
              <Button onClick={handlePrev} disabled={currentPage === 1}>Prev</Button>
              <Text mx="1rem">{currentPage} of {totalPages}</Text>
              <Button onClick={handleNext} disabled={currentPage === totalPages}>Next</Button>
            </Flex>
            </TabPanel>
            <TabPanel>
              {/* Additional TabPanel content */}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>

      <EnquiryTable columnData={enquiryColumnData} rowData={enquiryRowData} />
      
      <Box mt="3rem">
        <PaymentHistoryTable
          columnData={paymentHistoryColumnData}
          rowData={paymentHistoryRowData}
        />
      </Box>
    </>
  );
};
export default Project;
