export const ServiceTableRow = ["Service", "Sub Category", "Min Price", "Max Price"];

export const ServiceTableColumn = [
    {
        service: "Digital Marketing",
        subCategory: "SEO",
        maxPrice: "₹11000",
        minPrice: "₹22500",
    },
    {
        service: "Digital Marketing",
        subCategory: "SEO",
        maxPrice: "₹11000",
        minPrice: "₹22500",
    },
    {
        service: "Digital Marketing",
        subCategory: "SEO",
        maxPrice: "₹11000",
        minPrice: "₹22500",
    },
    {
        service: "Digital Marketing",
        subCategory: "SEO",
        maxPrice: "₹11000",
        minPrice: "₹22500",
    },
    {
        service: "Digital Marketing",
        subCategory: "SEO",
        maxPrice: "₹11000",
        minPrice: "₹22500",
    },
    {
        service: "Digital Marketing",
        subCategory: "SEO",
        maxPrice: "₹11000",
        minPrice: "₹22500",
    },
];

export const States = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Maharashtra",
    "Madhya Pradesh",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Tripura",
    "Telangana",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
]
export const Fit=[
    "Founder",
    "C-Executive",
    "Manager",
    "Project Manager",
    "Employee",
    "Agency",
    "Freelancer",
    "Other"
]

export const Gender=[
    "Male",
    "Female",
    "Other"
]

export const Employees=[
    "1-10",
    "10-25",
    "25-50",
    "50-100",
    "100-200",
    "200+"
]

export const BusinessStages=[
    "Early Stage",
    "Idea",
    "Pre-Revenue",
    "Growth",
    "Maturity",
    "Expansion",
    "Renewal",
    "Decline"
]

export const Industries=[
    "Information Technology",
    "Healthcare and Pharmaceuticals",
    "Finance and Banking",
    "Media and Entertainment",
    "Agriculture and Food Production"
]

export const Services=[
  "Press Release",
  "Website Development",
  "Digital Marketing",
  "Graphic Design",
]

export const Business=[
    { label: 'Market Competition', isChecked: false },
    { label: 'lack of Digital Presense', isChecked: false },
    { label: 'Weak Social Media', isChecked: false },
    { label: 'Branding', isChecked: false },
    { label: 'Higher CAC', isChecked: false },
    { label: 'Funding Issues', isChecked: false },
    { label: 'Customer Retention', isChecked: false },
    { label: 'Financial Model', isChecked: false },
    { label: 'Business Model', isChecked: false },
    { label: 'Pricing Model', isChecked: false },
    { label: 'Other', isChecked: false },
    // "Market Competition",
    // "lack of Digital Presense",
    // "Weak Social Media",
    // "Branding",
    // "Higher CAC",
    // "Funding Issues",
    // "Customer Retention",
    // "Financial Model",
    // "Business Model",
    // "Pricing Model",
    // "Other"
]
export const Goal=[
    { label: 'Start a business', isChecked: false },
    { label: 'Establishment of Business', isChecked: false },
    { label: 'Grow Your Business', isChecked: false },
    { label: 'Improving Efficiency', isChecked: false },
    { label: 'Accessing Specialized Expertise', isChecked: false },
]
export const Platform =[
    { label: 'For Getting Service', isChecked: false },  
    { label: 'To Get Business Support', isChecked: false },
    { label: 'Learning about Startup or Business', isChecked: false },
    { label: 'Use as Growth Advisor', isChecked: false },
]

