import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Stack,
  Text,
  useToast
} from "@chakra-ui/react";
import {
  collection,
  doc,
  getDocs,
  setDoc,
  query,
  updateDoc,
  where
} from "firebase/firestore";
import { auth } from "config/firebase";

import { onAuthStateChanged } from "firebase/auth";
import { db } from "config/firebase";
import { Business, Goal, Platform } from "../data/ServiceData";
import { IoIosArrowUp } from "react-icons/io";
import { useMediaQuery } from "@mui/material";
const Goals = () => {
  const [partnerId, setPartnerId] = useState("");
  const toast = useToast();
  const [business, setBusiness] = useState(Business);
  const [goals, setGoals] = useState(Goal);
  const [platForm, setPlatForm] = useState(Platform);
  const [check, setCheck] = useState(false);
  const isMobile=useMediaQuery('(max-width:640px)');
  const [UserId,setUserId]=useState();
  const [formData,setFormData]=useState({
    goals:{
      businessChallenges:[],
      goal:[],
      usingPlatform:[]
    }
  });

  useEffect(()=>{
    const unsubscribe=onAuthStateChanged(auth,(user)=>{
      if(user){
        const userid=user.uid;
        setUserId(userid);
      }else{
        console.log("user does not exist in goal section");
      }
    });

    return ()=>unsubscribe();
  },[]);

  
  useEffect(()=>{
    getData();
  },[UserId]);


  const filterData=()=>{
    let filteredBusiness=business.filter((item)=>item.isChecked);
    let labelBusiness=filteredBusiness.map((item)=>item.label);

    let filteredGoal=goals.filter((item)=>item.isChecked);
    let labelGoal=filteredGoal.map((item)=>item.label);
    
    let filteredPlatform=platForm.filter((item)=>item.isChecked);
    let labelPlatform=filteredPlatform.map((item)=>item.label);

    return {
      goals:{
        businessChallenges:[...labelBusiness],
        goal:[...labelGoal],
        usingPlatform:[...labelPlatform],
      }
    }
  }



  const addDetails=async()=>{
    try{
      const profileDocRef=collection(db,"userProfiles");
      const q=query(profileDocRef,where("uid","==",UserId))
      const querySnapshot=await getDocs(q);
      
      const updatedData=filterData();

      const finalData={
        ...formData,
        ...updatedData
      }

      if(!querySnapshot.empty){
        const docId=querySnapshot.docs[0].id;
        const docRef=doc(db,"userProfiles",docId);
        await updateDoc(docRef, finalData);
        toast({
          title:'Data Updated successfully!',
          description:'The user data was succesfully updated!',
          status:'success',
          duration:'2500',
          isClosable:true,
        })
      }
      
    }catch(error){
      console.log(error.message);
    }
  }

  const handleSubmit=(e)=>{
    e.preventDefault();
    addDetails();
  }

  const handleCheckBusiness = (value, rowIndex) => {
    setBusiness((prev) => {
      const updatedBusiness = [...prev];
      updatedBusiness[rowIndex] = {
        ...updatedBusiness[rowIndex],
        isChecked: value,
      };
      return updatedBusiness;
    });
  };

  const handleCheckGoals = (value,rowIndex) =>{
    setGoals((prev)=>{
      const updateGoals = [...prev];
      updateGoals[rowIndex] = {
        ...updateGoals[rowIndex],
        isChecked: value,
      };
      return updateGoals;
    })
  };

  const handleCheckPlatform = (value,rowIndex)=>{
    setPlatForm((prev)=>{
      const updatePlatform = [...prev];
      updatePlatform[rowIndex]={
        ...updatePlatform[rowIndex],
        isChecked:value,
      }
      return updatePlatform;
    })
  }

  const setCheckboxBusiness=(array)=>{
    setBusiness((prev)=>{
      return prev.map((item)=>({
        ...item,
        isChecked:array.includes(item.label),
      }))
    })
}

const setCheckboxGoals=(array)=>{
  setGoals((prev)=>{
    return prev.map((item)=>({
      ...item,
      isChecked:array.includes(item.label),
    }))
  })
}

const setCheckboxPlatform=(array)=>{
  setPlatForm((prev)=>{
    return prev.map((item)=>({
      ...item,
      isChecked:array.includes(item.label),
    }))
  })
}

  const getData=async()=>{
    try{
      if(!UserId){
        console.log("User Id does not exist!")
      }
      else{
        const profileDocRef=collection(db,"userProfiles");
        const q=query(profileDocRef,where("uid","==",UserId));
        const querySnapshot=await getDocs(q);
        if(!querySnapshot.empty){
          const data=querySnapshot.docs[0].data();
          const goalsData=data.goals;

          setCheckboxBusiness(goalsData.businessChallenges);
          setCheckboxGoals(goalsData.goal);
          setCheckboxPlatform(goalsData.usingPlatform);
          
        }
      }
      
    }catch(error){
      console.log(error.message);
      }
  }

  return (
    <Box
      w="100%"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      padding="2rem"
    >
      <Text fontSize="25px" fontWeight="bold" marginBottom="1rem">
        <span style={{ color: "#407BFF" }}>Get Personalized</span>{" "}
        <span style={{ color: "#455A64" }}>Business Assessment</span>
      </Text>
      <form onSubmit={handleSubmit}>
        <SimpleGrid columns={isMobile?1:2} spacing={10}>
          <Flex flexDirection="column">
            <Text
              fontSize="15px"
              fontWeight={600}
              color="#455A64"
              marginBottom={1}
            >
              Current Challenges in Your Business
            </Text>
            <Box
              w="100%"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              padding="1rem"
              backgroundColor="#fff"
            >
              <Text float="inline-end" color="#1C6ED0"><IoIosArrowUp /></Text>
              {business.map((item, index) => {
                return (
                  <Stack spacing={10} direction="row" key={index}>
                    <Checkbox size='lg' colorScheme='blue' onChange={(e) => handleCheckBusiness(e.target.checked, index)}
                      isChecked={item?.isChecked??false}>
                      <Text color="#455A64">{item?.label}</Text>
                    </Checkbox>
                  </Stack>
                );
              })}
            </Box>
          </Flex>
          <Flex flexDirection="column">
            <Text
              fontSize="15px"
              fontWeight={600}
              color="#455A64"
              marginBottom={1}
            >
              Your Goal
            </Text>
            <Box
              w="100%"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              padding="2rem"
              backgroundColor="#fff"
            >
              <Text float="inline-end" color="#1C6ED0"><IoIosArrowUp /></Text>
              {goals.map((item, index) => {
                return (
                  <Stack spacing={10} direction="row" key={index}>
                    <Checkbox size="lg" colorScheme="blue" onChange={(e) => handleCheckGoals(e.target.checked, index)} isChecked={item?.isChecked??false}>
                      <Text color="#455A64">{item?.label}</Text>
                    </Checkbox>
                  </Stack>
                );
              })}
            </Box>
          </Flex>
          <Flex flexDirection="column">
            <Text
              fontSize="15px"
              fontWeight={600}
              color="#455A64"
              marginBottom={1}
            >

              We are not just a service provider so
              how would you like to use our platform?
            </Text>
            <Box
              w="100%"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              padding="1rem"
              backgroundColor="#fff"
            >
              <Text float="inline-end" color="#1C6ED0"><IoIosArrowUp /></Text>
              {platForm.map((item, index) => {
                return (
                  <Stack spacing={10} direction="row" key={index}>
                    <Checkbox size="lg" colorScheme="blue" onChange={(e) => handleCheckPlatform(e.target.checked, index)} isChecked={item?.isChecked??false}>
                      <Text color="#455A64">{item?.label}</Text>
                    </Checkbox>
                  </Stack>
                );
              })}
            </Box>
          </Flex>
        </SimpleGrid>
        <Button mt="3" colorScheme="blue" type="submit">
          Save
        </Button>
      </form>
    </Box>
  );
};

export default Goals;
