import Card from 'components/card/Card';
import { HSeparator } from 'components/separator/Separator';
import React from 'react';
import { Text, useBreakpointValue } from '@chakra-ui/react';

const CustomCard = ({ card }) => {
  
  const cardWidth = useBreakpointValue({ base: '45%', lg: '30%' }); 

  return (
    <Card
      style={{
        width: cardWidth,  
        boxShadow: '2px 4px 14px 0px #E5A8CC',
        marginBottom: '2rem',
      }}
      bgColor={card?.bgColor}
    >
      <Text color="#1B2559" fontWeight="bold" fontSize="1.25rem">
        {card?.project}
      </Text>
      <Text color="#1B2559" fontWeight="bold">{card?.number}</Text>
      <HSeparator />
    </Card>
  );
};

export default CustomCard;
